const isProd = (process.env.STAGE_NAME || process.env.NEXT_PUBLIC_STAGE_NAME || 'dev').includes('prod');
const config = {
    isProd,
    env: process.env.NODE_ENV,
    stage: process.env.STAGE_NAME || 'dev',
    mode: process.env.MODE,
    VERSION: process.env.VERSION || 'NA',
    autohostApiEndpoint: process.env.AUTOHOST_API_ENDPOINT || 'http://localhost:3030',
    cookieName: process.env.AUTOHOST_COOKIE_NAME || 'autohost',
    SEGMENT_API_KEY: process.env.SEGMENT_API_KEY,
    ROLLBAR_API_KEY: process.env.ROLLBAR_API_KEY,
    STRIPE_API_KEY: process.env.STRIPE_API_KEY || 'pk_test_Gr1VFVap9fZSkESwSyzF8DQX',
    SEON_PUBLIC_KEY: process.env.SEON_PUBLIC_KEY,
    ASSET_HOST: process.env.ASSET_HOST || '',
    DATADOG_RUM_APP_ID: (process.env.DATADOG_RUM_APP_ID) ? process.env.DATADOG_RUM_APP_ID : (isProd ? '1574fa0c-a69f-418e-9419-a6a27c826199' : '6ceea151-b435-4f02-b133-1ac0f4386b69'),
    DATADOG_RUM_CLIENT_TOKEN: (process.env.DATADOG_RUM_CLIENT_TOKEN) ? process.env.DATADOG_RUM_CLIENT_TOKEN : (isProd ? 'pub21d49ca6f3e1cee64437b4b09c27fa17' : 'pube4458582378fcc1d17f0be5822516b09'),
    NEXT_PUBLIC_EMBEDED_COMPONENTS_URL: (process.env.NEXT_PUBLIC_EMBEDED_COMPONENTS_URL) ? process.env.NEXT_PUBLIC_EMBEDED_COMPONENTS_URL : (isProd ? 'https://embed.autohost.ai/' : 'https://embed.autohost.ca/'),
    FLAGSMITH_ENVIRONMENT_KEY: process.env.FLAGSMITH_ENVIRONMENT_KEY || '',
    FLAGSMITH_API_URL: process.env.FLAGSMITH_API_URL
};

export default config;
